import React from 'react';
import ImageGallery from "./ImageGallery";

type Props = {
  productData: any;
  closeModal: () => void;
};

const ProductModal: React.FC<Props> = ({productData, closeModal}) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50" onClick={closeModal}>
      <div
        className="bg-white rounded-lg p-8 mx-4 my-4 overflow-y-auto max-h-[calc(100vh-4rem)]"
        onClick={(e) => e.stopPropagation()}
      >
        <div>
          <div
            className="grid grid-cols-1 grid-rows-[repeat(4,_auto)] md:grid-rows-[repeat(3, auto)]  md:grid-cols-[1fr_25%] lg:grid-cols-[1fr_33%] gap-x-8 lg:items-start gap-y-2">
            <div className="col-start-1 text-left">
              <h1 className="text-2xl font-semibold tracking-tight text-gray-900 mt-2">
                {productData.name}
              </h1>
              <div className="relative bg-white rounded-md -space-y-px max-w-4xl col-start-1 mt-4">
                <div className="relative group border p-4 md:pl-4 md:pr-6 focus:outline-none border-gray-200">
                  <div className="text-gray-600 md:flex md:flex-row md:items-center">
                    <div>Art. nr: {productData.product_number}</div>
                    {productData.gtin && (
                      <>
                        <div className="mx-2 hidden md:block">|</div>
                        <div>GTIN: {productData.gtin}</div>
                      </>
                    )}
                    {productData.nobb_nr && (
                      <>
                        <div className="mx-2 hidden md:block">|</div>
                        <div>NOBB: {productData.nobb_nr}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="prose max-w-4xl space-y-3 text-sm col-start-1 product-description">
                {productData.description && (
                  <>
                    <h3 className="sr-only">Description</h3>
                    <div className="text-gray-700">{productData.description}</div>
                  </>
                )}
                {productData.features && <div dangerouslySetInnerHTML={{__html: productData.features}}/>}
              </div>
              {productData.svanemerke_certificates && productData.svanemerke_certificates.length > 0 && (
                <div className="mt-3">
                  {productData.svanemerke_certificates.map((cert: any) => (
                    <a key={cert.label} href={cert.url} target="_blank"
                       className="link text-sm text-sky-500 hover:text-sky-800">
                      {cert.label}
                    </a>
                  ))}
                </div>
              )}
              {productData.airfow_certificates && productData.airfow_certificates.length > 0 && (
                <div className="col-start-1">
                  <div className="flex flex-row gap-2 lg:gap-6 flex-wrap gap-y-3 pt-3">
                    {productData.airfow_certificates.map((cert: any) => (
                      <a key={cert.label} href={cert.url || cert.document_url} target="_blank" title={cert.label}>
                        <img className="h-16" src={cert.image_url} alt={cert.label}/>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <ImageGallery images={productData.product_images_urls}/>
          </div>
          <a
            href={productData.url}
            rel="noopener noreferrer"
            target="_blank"
            className="block mt-4 text-blue-500 hover:underline"
          >
            Go to full product details page
          </a>
          <button
            onClick={closeModal}
            className="block mt-4 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
