import React, { useState, useEffect } from 'react';

type Props = {
  images: any[];
};

const ImageGallery: React.FC<Props> = ({ images }) => {
  const [activeImage, setActiveImage] = useState(images[0]);
  const [fullScreen, setFullScreen] = useState(false);

  const handleImageClick = (image: any) => {
    setActiveImage(image);
  };

  const handlePrev = () => {
    const currentIndex = images.findIndex(img => img.media_code === activeImage.media_code);
    const prevIndex = (currentIndex - 1 + images.length) % images.length;
    setActiveImage(images[prevIndex]);
  };

  const handleNext = () => {
    const currentIndex = images.findIndex(img => img.media_code === activeImage.media_code);
    const nextIndex = (currentIndex + 1) % images.length;
    setActiveImage(images[nextIndex]);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (!fullScreen) return;
    switch (event.key) {
      case 'Escape':
        setFullScreen(false);
        break;
      case 'ArrowLeft':
        handlePrev();
        break;
      case 'ArrowRight':
        handleNext();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [fullScreen, activeImage]);

  return (
    <div className="image_gallery row-start-2 md:col-start-2 md:row-span-full flex flex-col-reverse relative max-w-2xl">
      <div className="mt-6 w-full max-w-2xl mx-auto lg:max-w-none">
        <div className="flex flex-row flex-wrap @container" aria-orientation="horizontal">
          {images.map((image, index) => (
            <div key={index} className="p-2 basis-1/4 md:basis-1/2 lg:basis-1/4 @sm:basis-1/4 aspect-square">
              <button
                className="w-full h-full relative bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                onClick={() => handleImageClick(image)}
              >
                <span className="absolute inset-0 rounded-md overflow-hidden">
                  <img src={image.small_thumbnail_url} alt="" className="w-full h-full object-center object-contain" />
                </span>
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full h-full aspect-square relative">
        {!activeImage.is_video && (
          <button onClick={() => setFullScreen(true)} className="grid grid-cols-1 grid-rows-1 place-content-center place-items-center w-full h-full">
            <img src={activeImage.image_url} alt="" className="w-full h-full object-center object-contain sm:rounded-lg col-start-1 row-start-1" />
            <img src={activeImage.thumbnail_url} alt="" className="w-full h-full object-center object-contain sm:rounded-lg col-start-1 row-start-1" />
          </button>
        )}
      </div>
      {fullScreen && (
        <div className="fixed inset-0 z-50 bg-gray-800/90 p-10 px-40 py-24 flex items-center justify-center">
          <div className="h-full flex items-center">
            {!activeImage.is_video && (
              <img
                src={activeImage.image_url}
                alt=""
                className="w-full h-full object-center object-contain rounded-lg"
                onClick={() => setFullScreen(false)}
              />
            )}
            {activeImage.is_video && (
              <div className="w-full h-full flex items-center justify-center">
                <iframe
                  src={`${activeImage.original_url}?autoplay=1`}
                  width="800"
                  className="aspect-video rounded-lg"
                  allow="autoplay"
                  title={activeImage.media_code}
                ></iframe>
              </div>
            )}
          </div>
          <a
            href={activeImage.original_url}
            target="_blank"
            rel="noopener noreferrer"
            className="absolute top-0 left-0 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg"
          >
            {window.gettext('Open Full Image')}
          </a>
          <button className="absolute top-0 right-0 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg" onClick={() => setFullScreen(false)}>
            {window.gettext('Close')}
          </button>
          <button className="absolute left-0 top-1/2 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg" onClick={handlePrev}>
            {window.gettext('Previous')}
          </button>
          <button className="absolute right-0 top-1/2 bg-gray-200 hover:bg-gray-300 px-4 py-2 rounded-lg" onClick={handleNext}>
            {window.gettext('Next')}
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
