import React from 'react';
import classNames from 'classnames';

type Props = {
  spaces: any;
  activeRoom: string;
  setActiveRoom: (roomType: string) => void;
  selectedData: any;
  recommendations: any;
};

const Spaces: React.FC<Props> = ({ spaces, activeRoom, setActiveRoom, selectedData, recommendations }) => {
  const checkIfAllSelected = (spaceCode: string, counter: number) => {
    const roomCode = `${spaceCode}${counter}`;
    const roomRecommendations = recommendations[spaceCode];
    const selectedRoomData = selectedData[roomCode];

    if (!selectedRoomData || !selectedRoomData.selectedProducts) return false;

    const selectedRating = selectedRoomData.rating;
    const recommendedArticlesCount = roomRecommendations.articles[selectedRating].length;
    const selectedArticlesCount = Object.keys(selectedRoomData.selectedProducts).length;
    // @ts-ignore
    const selectedArticles = Object.values(selectedRoomData.selectedProducts);
    return selectedArticlesCount === recommendedArticlesCount && selectedArticles.every((article: any) => article)
  };

  return (
    <ul className="flex flex-wrap sm:flex sm:flex-wrap gap-[8px] justify-center pb-3">
      {Object.keys(spaces).map((spaceCode) => {
        const space = spaces[spaceCode];
        return (
          [...Array(space.count)].map((_, counter) => {
            const roomCode = `${spaceCode}${counter}`;
            const isSelected = checkIfAllSelected(spaceCode, counter);
            const isActive = activeRoom === roomCode;

            return (
              <li
                key={roomCode}
                className={classNames(
                  'align-middle rounded-lg border-[#CBD1D7] border py-[6px] px-[10px] text-center text-[14px] text-[#1D1F22]',
                  { 'bg-fxgreen-light': isSelected }, // green-ish background if all selections are made
                  { 'border-3 border-fxgreen-dark ring-2 ring-lime-400': isActive } // thick border for active room
                )}
                title={isSelected ? 'All selections have been made for this room' : 'Please select products for the room'} // tooltip message
              >
                <button type="button" className="button-small" onClick={() => setActiveRoom(roomCode)}>
                  <span>{space.label} {counter + 1}</span>
                </button>
              </li>
            );
          })
        );
      })}
    </ul>
  );
};

export default Spaces;
