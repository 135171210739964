import React from 'react';
import { createRoot, Root } from 'react-dom/client';

export abstract class AbstractWidget {
  private _config: Object;
  private root: Root | null = null;

  constructor(public element: HTMLElement) {
    this.element = element;
    this._config = this.getConfig();
    this.initialize();
  }

  getDefaultConfig() {
    return {};
  }

  getConfig() {
    const parsedConfig = JSON.parse(this.element.getAttribute('data-react-widget-config') || '{}');
    return { ...this.getDefaultConfig(), ...parsedConfig };
  }

  abstract get UIComponent(): React.ComponentType<any>;

  get UIComponentProps() {
    return this._config;
  }

  initialize() {
    const UIComponent = this.UIComponent;
    this.root = createRoot(this.element);
    this.root.render(
        <UIComponent {...this.UIComponentProps} />
    );
  }

  destroy(element: HTMLElement) {
    if (this.root) {
      this.root.unmount();
    }
  }
}
