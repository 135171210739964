import React, {useState} from 'react';
import ProductModal from './ProductModal';
import OptionsModal from "./OptionsModal";

type Props = {
  key: string;
  productData: any;
  spaceData: any;
  suggestedArticles: any;
  roomCode: string;
  rating: string;
  article: any;
  setRoomSelection: (roomCode: string, rating: string, productCode: string, index: number, quantity: number) => void;
  itemIndex: number;
  getSelectedProductCode: (roomCode: string, rating: string, idx: number) => string | null;
  getPrice: (productCode: string) => { price: number, discount: number, currency: string };
};

const ProductCard: React.FC<Props> = ({
                                        key,
                                        productData,
                                        spaceData,
                                        suggestedArticles,
                                        roomCode,
                                        rating,
                                        article,
                                        setRoomSelection,
                                        itemIndex,
                                        getSelectedProductCode,
                                        getPrice
                                      }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [optionsModalOpen, setOptionsModalOpen] = useState(false);
  const selectedProductCode = getSelectedProductCode(roomCode, rating, itemIndex);

  const articleData = suggestedArticles[rating]?.[itemIndex];
  let spaceArticleData: any;
  const articleType = article.type

  if (articleType === 'multi_choice') {
    spaceArticleData = selectedProductCode
      ? articleData.value.find((product: any) => product.product_code === selectedProductCode)
      : articleData.value[0];
  } else {
    spaceArticleData = articleData.value;
  }

  const spaceProductData = productData.find((product: any) => product.product_number === spaceArticleData?.product_code)

  const handleSelect = () => {
    setRoomSelection(roomCode, rating, spaceProductData.product_number, itemIndex, spaceArticleData.quantity);
  };

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  const closeOptionsModal = () => setOptionsModalOpen(false);

  const price = spaceProductData ? getPrice(spaceProductData.product_number) : {price: 0, discount: 0, currency: 'NOK'};

  const discountedPrice = (price ? price.price : 0) * (1 - price.discount / 100);

  return spaceProductData && (
    <div key={key}>
      <div
        className="border-[#EBEBF0] border rounded-[12px] w-full relative bg-white mb-4 transform transition duration-500 hover:scale-105">
        <button type="button" className="grid sm:flex w-full p-[20px]">
          <div className="flex justify-between w-full">
            <div className="flex gap-4">
              <div className="flex flex-col items-center">
                <img
                  src={spaceProductData.product_images_urls?.[0]?.small_thumbnail_url}
                  alt=""
                  className="w-[80px] md:w-[100px] lg:w-[120px] h-[80px] md:h-[100px] lg:h-[120px] object-center object-contain cursor-pointer"
                  onClick={openModal}
                />
                {spaceProductData.airfow_certificates && spaceProductData.airfow_certificates.length > 0 && (
                  spaceProductData.airfow_certificates.map((cert: any) => (
                    <div
                      key={cert.label}
                      className="w-[36px] sm:w-[44px] h-[36px] sm:h-[44px] mt-2 grid place-content-center text-[#1D1F22] text-[16px] rounded-[8px] font-semibold">
                      <img src={cert.image_url} alt={cert.label}/>
                    </div>
                  ))
                )}
              </div>
              <div className="pl-0 pt-[12px] lg:pl-[40px] sm:pt-0 text-left">
                <p
                  className="font-semibold text-[#1D1F22] text-[16px]">{spaceArticleData.quantity} stk. {spaceProductData.name}</p>
                <div className="prose max-w-4xl space-y-3 text-sm col-start-1 product-description">
                  {spaceProductData.features && <div dangerouslySetInnerHTML={{__html: spaceProductData.features}}/>}
                  <p className="text-sm text-gray-600">
                    Pris hos Megaflis: {/* {currency}  */} <span
                    className="ml-2 font-extrabold">{discountedPrice.toFixed(0)},-</span>
                    {price.discount > 0 && (
                      <span className="line-through ml-2">{price.currency} {price.price.toFixed(2)}</span>
                    )}
                  </p>
                  {articleType === 'single_choice' || selectedProductCode ?
                    <button type='button'
                            className={`text-sm text-${selectedProductCode ? 'fxgreen-dark' : '[#217CBF]'} font-semibold`}
                            onClick={handleSelect}>
                      {selectedProductCode ? window.gettext('Selected. Click to deselect') : window.ngettext('Select this', 'Select these', spaceArticleData.quantity)}
                    </button> : <button type='button' className="text-sm text-[#217CBF] font-semibold"
                                        onClick={() => setOptionsModalOpen(true)}>
                      {window.gettext('View options')}
                    </button>}
                </div>
              </div>
            </div>
          </div>
        </button>
      </div>
      {modalOpen && <ProductModal productData={spaceProductData} closeModal={closeModal}/>}
      {optionsModalOpen &&
        <OptionsModal article={articleData} productData={productData} roomCode={roomCode} rating={rating}
                      itemIndex={itemIndex} setRoomSelection={setRoomSelection} getPrice={getPrice}
                      closeModal={closeOptionsModal}/>}
    </div>
  );
};

export default ProductCard;
