import { AbstractWidget } from './AbstractWidget';

export default class WidgetRegistrySingleton {
  private static instance: WidgetRegistrySingleton;
  private widgetClasses: { [key: string]: new (element: HTMLElement) => AbstractWidget } = {};

  private constructor() {
    // private to prevent direct instantiation
  }

  public static getInstance(): WidgetRegistrySingleton {
    if (!WidgetRegistrySingleton.instance) {
      WidgetRegistrySingleton.instance = new WidgetRegistrySingleton();
    }

    return WidgetRegistrySingleton.instance;
  }

  public registerWidgetClass(widgetName: string, widgetClass: new (element: HTMLElement) => AbstractWidget) {
    this.widgetClasses[widgetName] = widgetClass;
  }

  public getWidgetClass(widgetName: string): new (element: HTMLElement) => AbstractWidget {
    if (!this.widgetClasses[widgetName]) {
      throw new Error(`Widget ${widgetName} not registered`);
    }
    return this.widgetClasses[widgetName];
  }

  public initializeWidget(element: HTMLElement) {
    const widgetName = element.getAttribute('data-react-widget');
    if (!widgetName) {
      throw new Error('Widget name attribute (data-react-widget) is missing.');
    }
    const WidgetClass = this.getWidgetClass(widgetName);
    new WidgetClass(element).initialize();
  }

  public initializeWidgets() {
    const elements = document.querySelectorAll('[data-react-widget]');
    elements.forEach((element) => {
      this.initializeWidget(element as HTMLElement);
    });
  }

  public destroyWidget(element: HTMLElement) {
    const widgetName = element.getAttribute('data-react-widget');
    if (!widgetName) {
      throw new Error('Widget name attribute (data-react-widget) is missing.');
    }
    const WidgetClass = this.getWidgetClass(widgetName);
    const widgetInstance = new WidgetClass(element);
    widgetInstance.destroy(element);
  }

  public destroyWidgets() {
    const elements = document.querySelectorAll('[data-react-widget]');
    elements.forEach((element) => {
      this.destroyWidget(element as HTMLElement);
    });
  }
}
