import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import persist from "@alpinejs/persist"
import * as qs from "qs"
import {v4 as uuidv4} from "uuid"

Alpine.plugin(collapse);
Alpine.plugin(persist);

// @ts-ignore // pycharm not working with global.d.ts
window.Alpine = Alpine;

Alpine.data(
  "searchApp",
  function searchApp({current_category, languageCode}) {
    return {
      search_open: !!new URLSearchParams(location.search).get("search_string"),
      search_history: JSON.parse(localStorage.getItem('search_history')) || [], // Load history from localStorage or set to empty array if it doesn't exist
      search_options: {
        search_string: "",
        search_current_category: false,
        search_category: "",
        page: 1,
        current_category,
      },
      search_results: {
        count: 0,
        page: 0,
        uuid: "",
        total_pages: 0,
        show_load_more: false,
        html: "",
      },
      languageCode: languageCode,
      loading: false,
      timeout: null,

      delaySearch() {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.onSearch()
        }, 500)
      },
      saveSearchHistory(search_string) {
        // Check if the search string already exists in the history
        if (search_string) {
          const index = this.search_history.indexOf(search_string);

          if (index > -1) {
            // If it does, remove it
            this.search_history.splice(index, 1);
          }

          // Add the search string to the beginning of the array
          this.search_history.unshift(search_string);

          // Only keep the latest 10 search strings
          this.search_history = this.search_history.slice(0, 10);

          // Save to localStorage
          localStorage.setItem('search_history', JSON.stringify(this.search_history));
        }
      },

      onSearch() {
        const url = new URL(window.location.href);
        url.searchParams.set(
          "search_string",
          this.search_options.search_string
        );
        this.saveSearchHistory(this.search_options.search_string);
        if (this.search_options.search_string) {
          // url.searchParams.set(
          //   "search_current_category",
          //   this.search_options.search_current_category
          // );
          // url.searchParams.set(
          //   "search_category",
          //   this.search_options.search_category
          // );
          history.replaceState(null, document.title, url.toString());
          this.loading = true;
          fetch(
            `/search?${qs.stringify({
              ...this.search_options,
              languageCode: this.languageCode,
            })}`,
            {
              credentials: "include",
            }
          )
            .then((r) => r.json())
            .then((json) => {
              this.search_results = {
                ...json,
                html:
                  this.search_results.uuid === json.uuid
                    ? this.search_results.html + json.html
                    : json.html,
              };
              this.loading = false;
            });
        }
      },

      search_load_more() {
        this.search_options.page += 1;
        this.onSearch({...this.search_options});
      },

      init() {
        this.$watch("search_options.search_string", () => {
          this.search_options.page = 1;
          this.search_options.html = "";
          this.search_results.count = -1;
          // Idea is the UUID is to track if the search results should append or not, and be reset on any change.
          this.search_options.uuid = uuidv4();
          this.delaySearch();
        });
        this.$watch("search_open", (search_open) => {
          if (this.search_results_html === "") {
            this.onSearch();
          }
        });

        const search_url = new URLSearchParams(location.search).get(
          "search_string"
        );
        if (search_url) {
          this.search_options.search_string = search_url;
          this.onSearch({...this.search_options});
        }
      },

      toggle_search() {
        this.search_open = !this.search_open;
        if (this.search_open) {
          setTimeout(() => this.$refs.search_input.focus(), 250);
        }
        // TODO: we shoud clear search_string on close, to make page less stateful...
      },
      close_search() {
        if (this.search_open) {
          this.search_open = false;
        }
      },
    };
  }
);

export function initializeAlpine () {
  Alpine.start();
}
