import React from "react";
import Spaces from "./Spaces";
import RoomRecommendations from "./RoomRecommendations";
import CartModal from "./CartModal"; // Import the CartModal component
import axios from 'axios';

interface Product {
  productCode: string;
  quantity: string;
}

interface Room {
  rating: string;
  selectedProducts: Record<string, Product>;
}

interface WizardResultPageProps {
  back_url: string,
  products: any,
  spaces: any,
  recommendations: any,
  price_list: { [key: string]: { price: number, discount: number, currency: string } },
  company_logo_url: string | null,
  populate_cart_url: string,
  config_key: string,
}

interface WizardResultPageState {
  activeRoom: string;
  activeRoomRatings: { [key: string]: string };
  selectedData: { [key: string]: Room };
  isCartModalOpen: boolean; // Add state to manage cart modal visibility
}

class WizardResultPage extends React.Component<WizardResultPageProps, WizardResultPageState> {
  constructor(props: WizardResultPageProps) {
    super(props);
    this.state = this.makeInitialState(props);
  }

  makeInitialState(props: WizardResultPageProps): WizardResultPageState {
    return {
      activeRoom: Object.keys(props.spaces)[0] + '0',
      activeRoomRatings: {},
      selectedData: {},
      isCartModalOpen: false, // Initialize cart modal state
    };
  }

  get companyLogoUrl() {
    return this.props.company_logo_url || "https://res.cloudinary.com/flexit/image/upload/v1718123789/climat_wizard/example_logo_alt";
  }

  setRoomSelection = (roomCode: string, rating: string, productCode: string, index: number, quantity: number) => {
    const roomData = this.state.selectedData[roomCode];
    if (!roomData || roomData.rating !== rating || !roomData.selectedProducts) {
      this.setState((prevState) => ({
        selectedData: {
          ...prevState.selectedData,
          [roomCode]: {
            rating: rating,
            selectedProducts: {
              [index]: {
                productCode: productCode,
                quantity: quantity.toString(),
              },
            },
          },
        },
      }));
    } else {
      this.setState((prevState) => ({
        selectedData: {
          ...prevState.selectedData,
          [roomCode]: {
            rating: rating,
            selectedProducts: {
              ...roomData.selectedProducts,
              [index]: roomData.selectedProducts[index]?.productCode === productCode ? undefined : {
                productCode: productCode,
                quantity: quantity.toString(),
              },
            },
          },
        },
      }));
    }
  };

  getSelectedProductCode = (roomCode: string, rating: string, idx: number) => {
    try {
      if (this.state.selectedData[roomCode] && this.state.selectedData[roomCode].rating === rating) {
        return this.state.selectedData[roomCode].selectedProducts[idx]?.productCode;
      }
      return null;
    } catch (e) {
      console.error(e);
      console.log(roomCode, rating, idx, this.state.selectedData[roomCode]);
      return null;
    }
  };

  getSelectedRatingForRoom = (roomCode: string): string => {
    return this.state.activeRoomRatings[roomCode] || 'good';
  };

  setRating = (roomCode: string, rating: string) => {
    this.setState((prevState) => ({
      activeRoomRatings: {
        ...prevState.activeRoomRatings,
        [roomCode]: rating,
      },
    }));
  };

  toggleCartModal = () => {
    this.setState((prevState) => ({
      isCartModalOpen: !prevState.isCartModalOpen
    }));
  };

  proceedToCartCallBack = () => {
    // transform selectedData to object with productCode as key and quantity as value
    const selectedProducts = Object.values(this.state.selectedData).flatMap(room => Object.values(room.selectedProducts))
      .filter(product => product)
      .reduce((acc, product) => {
        acc[product.productCode] = parseInt(product.quantity, 10);
        return acc;
      }, {});
    // make api request to add products to cart and get cart id in response
    axios.post(
      this.props.populate_cart_url,
      {config_key: this.props.config_key, products: selectedProducts},
      {
        headers: {  // Headers need to be inside a `headers` object
          'Content-Type': 'application/json',
          'X-CSRFToken': window.csrfToken
        }
      }
    ).then(response => {
      // redirect to cart page
      console.log(response)
      window.location.href = response.data.redirect_url;
    }).catch(error => {
      console.error('Error adding products to cart', error);
    })
  };

  getPrice = (productCode: string) => {
    const priceData = this.props.price_list[productCode];
    if (priceData) {
      return priceData;
    }
    // Generate random price if not available in price_list
    return {price: 0, discount: 0, currency: ''};
  };

  renderNavBar() {
    return (
      <nav className="bg-white">
        <div className="flex justify-between items-center px-2 py-3">
          <div className="flex items-center">
            <img
              src={this.companyLogoUrl}
              alt="Flexit AS" className="h-10 w-auto mr-2"/>
          </div>
          <div className="flex items-center print:hidden hidden lg:block" style={{cursor: 'default'}}>
            <span className="px-2 text-uppercase">{window.gettext("Your home")}</span>
            <span className="px-2 text-uppercase">{window.gettext("Ventilation")}</span>
            <span className="px-2 text-uppercase">{window.gettext("Your project")}</span>
            <span className="px-2 text-uppercase text-black">{window.gettext("Recommendations")}</span>
          </div>
          <div className="print:hidden">
          </div>
        </div>
      </nav>
    );
  }

  renderSpaceBreadcrumbs() {
    return (
      <Spaces
        spaces={this.props.spaces}
        activeRoom={this.state.activeRoom}
        setActiveRoom={(roomType: string) => {
          this.setState({activeRoom: roomType});
        }}
        selectedData={this.state.selectedData}
        recommendations={this.props.recommendations}
      />
    );
  }

  renderRecommendations() {
    const {recommendations} = this.props;
    const {activeRoom} = this.state;
    const roomType = activeRoom.replace(/[0-9]/g, ''); // Extract the room type without the index
    const selectedRating = this.getSelectedRatingForRoom(activeRoom);

    return (
      <RoomRecommendations
        roomCode={activeRoom}
        spaceData={recommendations[roomType]}
        selectedRating={selectedRating}
        setRating={this.setRating}
        getSelectedProductCode={this.getSelectedProductCode}
        setRoomSelection={this.setRoomSelection}
        productData={this.props.products}
        getPrice={this.getPrice}
      />
    );
  }

  renderSelectedProductInfo() {
    const {selectedData} = this.state;
    let selectedInfo = null;
    const selected = Object.keys(selectedData).length > 0 && Object.keys(selectedData).some((key) => Object.keys(selectedData[key].selectedProducts).length > 0 && Object.keys(selectedData[key].selectedProducts).some((idx) => !!selectedData[key].selectedProducts[idx]));
    if (!selected) {
      selectedInfo = <p className="">
        {window.gettext(
          "Click on room and select a product!"
        )}
      </p>
    } else {
      const totalSelectedProducts = Object.values(selectedData).reduce((sum: number, room) => {
        return sum + Object.values(room.selectedProducts).reduce((roomSum: number, product: Product) => {
          return roomSum + parseInt(product?.quantity || "0", 10);
        }, 0);
      }, 0);
      const roomsWithProducts = Object.values(selectedData).reduce((count, room) => {
        const hasProductWithQuantity = Object.values(room.selectedProducts).some(product => parseInt(product?.quantity || "0", 10) > 0);
        return hasProductWithQuantity ? count + 1 : count;
      }, 0);
      selectedInfo = <p className="text-[#217CBF] underline cursor-pointer" onClick={this.toggleCartModal}>

        {window.interpolate(
          window.ngettext('%(nproducts)s product selected', '%(nproducts)s products selected', totalSelectedProducts),
          {nproducts: totalSelectedProducts},
          true
        )}&nbsp;
        {window.interpolate(
          window.ngettext('for %(nrooms)s room', 'for %(nrooms)s rooms', roomsWithProducts),
          {nrooms: roomsWithProducts},
          true
        )}
      </p>
    }
    return <div className="text-center pb-4">
      {selectedInfo}
    </div>
  }

  renderMainContent() {
    return (
      <>
        <section className="bg-white">
          <div className="container">
            <div className="text-center mt-3">
              <a href={this.props.back_url} className="text-[#217CBF] text-[16px] underline">
                {window.gettext("Back to the form")}
              </a>
            </div>
            <h2 className="leading-snug tracking-tight text-3xl font-semibold mt-5 mb-0">
              {window.gettext("Select products for rooms")}
            </h2>
            <p className="leading-7 text-gray-700 py-6">
              {window.gettext(
                "We have now found suggestions for your project. Click on the room and select a product " +
                "for each. Note that for some rooms, you can choose between a \"good\" and \"better\" solution."
              )}
              {/*<br/>*/}
              {/*<a href="http://inneklimaguiden.no" className="text-[#217CBF] underline">*/}
              {/*  {window.gettext("More about the best solution.")}*/}
              {/*</a>*/}
            </p>
            {this.renderSpaceBreadcrumbs()}
            {this.renderSelectedProductInfo()}

          </div>
        </section>
        <section className="bg-[#F7F7F7]">
          <div className="container max-w-5xl">
            <div
              className="text-center grid pt-5 gap-10 my-0 mx-auto px-0 sm:px-2 md:px-4 2xl:px-6 print:max-w-none">
              {this.renderRecommendations()}
            </div>
          </div>
        </section>
      </>
    );
  }

  render() {
    return [
      this.renderNavBar(),
      <div id="scroll_to_this_place" className="bg-[#00aeef] h-[2px] w-100"></div>,
      this.renderMainContent(),
      this.state.isCartModalOpen && (
        <CartModal
          selectedData={this.state.selectedData}
          products={this.props.products}
          spaces={this.props.spaces}
          price_list={this.props.price_list}
          closeModal={this.toggleCartModal}
          handleProceedToCart={this.proceedToCartCallBack}
        />
      ),
    ];
  }
}

export default WizardResultPage;
